var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ContactExpertBlock } from "@components/Block/ContactExpertBlock/ContactExpertBlock";
import { ContactListBlock } from "@components/Block/ContactListBlock/ContactListBlock";
import { ContentOptionsBlock } from "@components/Block/ContentOptionsBlock/ContentOptionsBlock";
import { DownloadBlockWrapper } from "@components/Block/DownloadBlock";
import { DownloadCenterCategoryBlock } from "@components/Block/DownloadCenterCategoryBlock";
import { DownloadCenterSubcategoryBlock } from "@components/Block/DownloadCenterSubcategoryBlock";
import { EmbeddedHtmlBlock } from "@components/Block/EmbeddedHtmlBlock";
import { FactsAndFigures } from "@components/Block/FactsAndFigures/FactsAndFigures";
import { FeaturedContentBlock } from "@components/Block/FeaturedContentBlock/FeaturedContentBlock";
import { FeaturedFactsBlock } from "@components/Block/FeaturedFactsBlock";
import { FeaturedPagesBlock } from "@components/Block/FeaturedPagesBlock";
import { FooterIconBlock } from "@components/Block/FooterIconBlock";
import FooterLinkBlock from "@components/Block/FooterLinkBlock";
import FooterTextBlock from "@components/Block/FooterTextBlock";
import { FormContainerBlock } from "@components/Block/Forms/FormContainerBlock";
import { IconWithTextListBlock } from "@components/Block/IconWithTextListBlock";
import { ImageBlock } from "@components/Block/ImageBlock/ImageBlock";
import { ImageGalleryBlock } from "@components/Block/ImageGalleryBlock";
import { ImageTeaserBlock } from "@components/Block/ImageTeaserBlock";
import { InteractiveMapBlock } from "@components/Block/InteractiveMapBlock/InteractiveMapBlock";
import { MsDynamicsConnectorBlock } from "@components/Block/MSDynamicsForm/MsDynamicsConnectorBlock";
import { MediaCarouselBlock } from "@components/Block/MediaCarouselBlock/MediaCarouselBlock";
import { NewsWallTeaserBlock } from "@components/Block/NewsWallTeaserBlock";
import { NewsletterBlock } from "@components/Block/NewsletterBlock";
import { QuickLinksBlock } from "@components/Block/QuickLinksBlock";
import { QuoteBlock } from "@components/Block/QuoteBlock";
import { SvgBlock } from "@components/Block/SvgBlock/SvgBlock";
import { TextAndImageBlock } from "@components/Block/TextAndImageBlock";
import { TextTeaserBlock } from "@components/Block/TextTeaserBlock";
import { VideoBlock } from "@components/Block/VideoBlock/VideoBlock";
import { DownloadItem } from "@components/DownloadItem/DownloadItem";
import { LocaleOverlay } from "@components/LocaleOverlay/LocaleOverlay";
import { ContentPage } from "@components/Page/ContentPage";
import { DownloadCenterDynamicPage } from "@components/Page/DownloadCenterDynamicPage";
import { DownloadCenterPage } from "@components/Page/DownloadCenterPage";
import { JobListingPage } from "@components/Page/JobListingPage";
import { LocationsOverviewPage, } from "@components/Page/LocationsOverviewPage";
import { NewsArticlePage } from "@components/Page/NewsArticlePage";
import { NewsWallPage } from "@components/Page/NewsWallPage";
import { PressSectionPage } from "@components/Page/PressSectionPage";
import { ProductPage } from "@components/Page/ProductPage";
import { SearchPage } from "@components/Page/SearchPage";
import { SectionLandingPage } from "@components/Page/SectionLandingPage";
import { SpecialtyStartPage } from "@components/Page/SpecialtyStartPage";
import { StartPage } from "@components/Page/StartPage";
import { UnsubscribeConfirmationPage } from "@components/Page/UnsubscribeConfirmationPage";
import { PrivacyNote } from "@components/PrivacyNote/PrivacyNote";
import { GlobalContentStoreContext } from "@/src/hooks/GlobalContentStore";
import { AccordionBlock } from "@components/Block/AccordionBlock/AccordionBlock";
import { RealTimeMarketingFormBlock } from "@components/Block/MSDynamicsForm/RealTimeMarketingFormBlock/RealTimeMarketingFormBlock";
import RichTextBlock from "@components/Block/RichTextBlock/RichTextBlock";
export function RenderComponent(props) {
    var _a = useContext(GlobalContentStoreContext), getByContentLink = _a.getByContentLink, getStartPageData = _a.getStartPageData, getImmediateByContentLink = _a.getImmediateByContentLink, getImmediateStartPageData = _a.getImmediateStartPageData;
    var _b = useState(getImmediateByContentLink(props.contentLink)), componentData = _b[0], setComponentData = _b[1];
    var _c = useState(getImmediateStartPageData()), startPageData = _c[0], setStartPageData = _c[1];
    useEffect(function () {
        setComponentData(getImmediateByContentLink(props.contentLink));
    }, [getImmediateByContentLink]);
    useEffect(function () {
        !componentData &&
            getByContentLink(props.contentLink).then(function (response) {
                setComponentData(response);
            });
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    if (!componentData || !startPageData)
        return null;
    var componentName = componentData.contentType.join("/");
    var theme = props.theme || "standard";
    switch (componentName) {
        case "Block/FooterTextBlock":
            return (React.createElement(FooterTextBlock, { data: componentData, className: props.className, context: props.context }));
        case "Block/FooterLinkBlock":
            return (React.createElement(FooterLinkBlock, { data: componentData, className: props.className, context: props.context }));
        case "Block/FooterImageLinksBlock":
            return (React.createElement(FooterIconBlock, { data: componentData, className: props.className }));
        case "Block/IconWithTextListBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(IconWithTextListBlock, { data: componentData, className: props.className }))));
        case "Block/AccordionBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(AccordionBlock, { data: componentData, className: props.className }))));
        case "Block/RichTextBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(RichTextBlock, { data: componentData, className: props.className }))));
        case "Block/FeaturedFactsBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: true },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FeaturedFactsBlock, { data: componentData, className: props.className }))));
        case "Block/EmbeddedHtmlBlock":
            var ThemedEmbeddedHtmlBlock = function () { return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(EmbeddedHtmlBlock, { data: componentData, className: props.className }))); };
            return theme === "navigation" || theme === "filters" ? (React.createElement(ThemedEmbeddedHtmlBlock, null)) : (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(ThemedEmbeddedHtmlBlock, null)));
        case "Block/QuoteBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(QuoteBlock, { data: componentData, className: props.className }))));
        case "Block/TextAndImageBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(TextAndImageBlock, { data: componentData, className: props.className }))));
        case "Block/ConsentBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(PrivacyNote, { data: componentData, className: props.className })));
        case "Block/ContentOptionsBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: true, hasCustomTopMargin: true },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(ContentOptionsBlock, { data: componentData, className: props.className }))));
        case "Block/FeaturedContentBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FeaturedContentBlock, { data: componentData, className: props.className }))));
        case "Block/FeaturedVideoBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FeaturedContentBlock, { data: componentData, className: props.className }))));
        case "Block/NewsWallListingBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: theme === "standard" },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(NewsWallTeaserBlock, { data: componentData, className: props.className, theme: theme }))));
        case "Block/LocaleSelectorBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(LocaleOverlay, { data: componentData, className: props.className })));
        case "Block/QuickLinksBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: theme === "homepage" },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(QuickLinksBlock, { data: componentData, className: props.className, theme: theme }))));
        case "Block/FeaturedPagesBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FeaturedPagesBlock, { data: componentData, className: props.className }))));
        case "Block/NewsletterSignupTeaserBlock":
            var ThemedNewsletterSignupTeaserBlock = function () { return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(NewsletterBlock, { data: componentData, className: props.className, theme: theme }))); };
            return theme === "navigation" || theme === "filters" ? (React.createElement(ThemedNewsletterSignupTeaserBlock, null)) : (React.createElement(TopMarginWrapper, { hasDarkBackground: true },
                React.createElement(ThemedNewsletterSignupTeaserBlock, null)));
        case "Block/VideoBlockCloudinary":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(VideoBlock, { data: componentData, className: props.className }))));
        case "Block/ImageTeaserBlock":
            var ThemedImageTeaserBlock = function () { return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(ImageTeaserBlock, { data: componentData, className: props.className, theme: theme }))); };
            return theme === "navigation" || theme === "filters" ? (React.createElement(ThemedImageTeaserBlock, null)) : (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(ThemedImageTeaserBlock, null)));
        case "Block/TextTeaserBlock": {
            var ThemedTextTeaserBlock = function () { return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(TextTeaserBlock, { data: componentData, className: props.className, theme: theme }))); };
            return theme === "navigation" || theme === "filters" ? (React.createElement(ThemedTextTeaserBlock, null)) : (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(ThemedTextTeaserBlock, null)));
        }
        case "Block/ImageBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(ImageBlock, { data: componentData, className: props.className }))));
        case "Block/ImageGalleryBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(ImageGalleryBlock, { data: componentData, className: props.className }))));
        case "Block/HdiGlobalForm":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FormContainerBlock, { data: componentData, className: props.className, isComponent: true }))));
        case "Block/ContactListBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(ContactListBlock, { data: componentData, className: props.className }))));
        case "Block/ContactExpertBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(ContactExpertBlock, { data: componentData, className: props.className }))));
        case "Block/DownloadBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: true },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(DownloadBlockWrapper, { data: componentData, className: props.className }))));
        case "Block/FactsAndFiguresBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: true },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(FactsAndFigures, { data: componentData, className: props.className }))));
        case "Block/DownloadCenterCategoryBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(DownloadCenterCategoryBlock, { data: componentData, className: props.className })));
        case "Block/DownloadCenterSubcategoryBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(DownloadCenterSubcategoryBlock, { data: componentData, className: props.className })));
        case "Block/ProductDownloadBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(DownloadItem, __assign({}, componentData.masterFile
                    .expandedValue, { localizedFiles: componentData.localizedFiles
                        .expandedValue, className: props.className }))));
        case "Block/MsDynamicsConnectorBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(MsDynamicsConnectorBlock, { data: componentData, className: props.className })));
        case "Block/RealTimeMarketingFormBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(RealTimeMarketingFormBlock, { data: componentData, className: props.className })));
        case "Block/InteractiveMapBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(InteractiveMapBlock, { data: componentData, className: props.className })));
        case "Media/DocumentFile":
            return (React.createElement(DownloadItem, __assign({}, componentData, { 
                // context={props.context}
                className: props.className })));
        case "Page/ContentPage":
            return (React.createElement(ContentPage, { data: componentData, className: props.className }));
        case "Page/JobListingPage":
            return (React.createElement(JobListingPage, { data: componentData, className: props.className }));
        case "Page/NewsWallPage":
            return (React.createElement(NewsWallPage, { data: componentData, className: props.className }));
        case "Page/NewsArticlePage":
            return (React.createElement(NewsArticlePage, { data: componentData, className: props.className }));
        case "Page/ProductPage":
            return (React.createElement(ProductPage, { data: componentData, className: props.className }));
        case "Page/LocationsOverviewPage":
            return (React.createElement(LocationsOverviewPage, { data: componentData, className: props.className }));
        case "Page/SectionLandingPage":
            return (React.createElement(SectionLandingPage, { data: componentData, className: props.className }));
        case "Page/SearchPage":
            return (React.createElement(SearchPage, { data: componentData, className: props.className }));
        case "Page/StartPage":
            return (React.createElement(StartPage, { data: componentData, className: props.className }));
        case "Page/SpecialtyStartPage":
            return (React.createElement(SpecialtyStartPage, { data: componentData, className: props.className }));
        case "Page/PressSectionPage":
            return (React.createElement(PressSectionPage, { data: componentData, className: props.className }));
        case "Page/DownloadCenterPage":
            return (React.createElement(DownloadCenterPage, { data: componentData, className: props.className }));
        case "Page/DownloadCenterDynamicPage":
            return (React.createElement(DownloadCenterDynamicPage, { data: componentData, className: props.className }));
        case "Page/UnsubscribeConfirmationPage":
            return (React.createElement(UnsubscribeConfirmationPage, { data: componentData, className: props.className }));
        case "Block/MediaCarouselBlock":
            return (React.createElement(Anchor, { anchor: componentData.anchor },
                React.createElement(MediaCarouselBlock, { data: componentData })));
        case "Block/SvgBlock":
            return (React.createElement(TopMarginWrapper, { hasDarkBackground: false },
                React.createElement(Anchor, { anchor: componentData.anchor },
                    React.createElement(SvgBlock, { data: componentData, className: props.className }))));
        default:
            return null;
    }
}
var useStyles = createUseStyles({
    anchor: {
        position: "relative",
        "& > span": {
            position: "absolute",
            top: -100,
        },
    },
});
function TopMarginWrapper(props) {
    return React.createElement(React.Fragment, null, props.children);
}
function Anchor(props) {
    var _a, _b;
    var styles = useStyles();
    return ((_a = props.anchor) === null || _a === void 0 ? void 0 : _a.value) ? (React.createElement("div", { className: styles.anchor },
        React.createElement("span", { id: (_b = props.anchor) === null || _b === void 0 ? void 0 : _b.value }),
        props.children)) : (React.createElement(React.Fragment, null, props.children) || null);
}
