var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { animations } from "@basics/animations";
import { colors } from "@basics/colors";
import { Container, onBefore, onBreakpoint, rem } from "@basics/layout";
import { pseudoSelectorShadow } from "@basics/mixins";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import Search from "./Search/Search";
var HEADER_TOP_CONTENT_HEIGHT = 40;
export var useStyles = createUseStyles({
    header: function (_a) {
        var liftHeaderUpByPx = _a.liftHeaderUpByPx;
        return ({
            position: "sticky",
            top: 0,
            zIndex: 99,
            transition: "transform 0.3s ease",
            transform: "translateY(-".concat(liftHeaderUpByPx, "px)"),
        });
    },
    headerMixins: __assign({}, onBefore("sm", {
        transform: "none !important",
    })),
    headerTop: __assign({ backgroundColor: colors.gray20, display: "none" }, onBreakpoint("sm", {
        display: "block",
    })),
    headerTopContent: {
        height: HEADER_TOP_CONTENT_HEIGHT,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& ul": {
            display: "flex",
            padding: 0,
            "& li": __assign(__assign({ listStyle: "none", margin: "0 ".concat(spacings.sam.divide(2)) }, onBreakpoint("md", {
                margin: "0 ".concat(spacings.sam),
            })), { "& a": __assign({}, customTypography(__assign(__assign(__assign({}, typography.textXSmall), { marginBottom: 0, color: colors.gray100, transition: "color 0.3s ease", "&:hover": {
                        color: colors.black,
                    } }), animations.linkChevronEffect), {}, {
                    marginBottom: 0,
                })) }),
        },
    },
    navLeft: {
        display: "flex",
        alignItems: "center",
        "& li:first-child": {
            marginLeft: 0,
        },
    },
    navRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    headerBottom: __assign(__assign({ display: "flex", alignItems: "center", height: 64, backgroundColor: colors.white, position: "relative", borderBottom: function (_a) {
            var openedMenu = _a.openedMenu, openedSearch = _a.openedSearch;
            return openedMenu || openedSearch ? "1px solid ".concat(colors.gray20) : 0;
        }, "&:after": __assign(__assign({}, pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)")), { height: 12, opacity: function (_a) {
                var openedMenu = _a.openedMenu, openedSearch = _a.openedSearch;
                return openedMenu || openedSearch ? 0 : 1;
            }, transition: "all 0.5s" }) }, onBreakpoint("sm", {
        borderBottom: function () { return 0; },
    })), onBreakpoint("md", {
        height: 80,
    })),
    headerBottomContent: {
        display: "flex",
        alignItems: "center",
    },
    logo: {
        display: "flex",
        marginRight: "auto",
        "& img": __assign(__assign({ width: 42, height: 17, objectFit: "contain" }, onBreakpoint("sm", {
            width: 60,
            height: 24,
        })), onBreakpoint("md", {
            width: 72,
            height: 29,
        })),
    },
    search: __assign(__assign({ marginRight: 36, cursor: "pointer", transition: "all .3s" }, FAIconStyles({
        width: 25,
        height: 25,
    })), onBefore("md", __assign({ marginRight: 24 }, FAIconStyles({
        width: 20,
        height: 20,
    })))),
    mainMenu: {
        padding: 0,
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& span": __assign(__assign(__assign(__assign({}, typography.textLarge), { marginBottom: 0, color: colors.gray100, display: "none" }), onBreakpoint("sm", {
            fontSize: 20,
            display: "block",
            marginRight: rem(spacings.sam.divide(2)),
        })), onBreakpoint("md", {
            fontSize: 24,
        })),
        "& svg": {
            color: colors.black,
        },
    },
    hamburgerIcon: function (_a) {
        var openedMenu = _a.openedMenu;
        return ({
            width: 21,
            height: 12,
            position: "relative",
            cursor: "pointer",
            "& div": {
                position: "absolute",
                height: 2,
                width: "100%",
                background: colors.black,
                opacity: 1,
                left: 0,
                transition: "transform 0.25s ease, opacity 0.25s ease",
                "&:nth-child(1)": {
                    top: openedMenu ? 7 : 0,
                    left: openedMenu ? "50%" : 0,
                    width: openedMenu ? "0%" : "100%",
                },
                "&:nth-child(2)": {
                    top: 7,
                    transform: openedMenu ? "rotate(45deg)" : "rotate(0deg)",
                },
                "&:nth-child(3)": {
                    top: 7,
                    transform: openedMenu ? "rotate(-45deg)" : "rotate(0deg)",
                    opacity: openedMenu ? 1 : 0,
                },
                "&:nth-child(4)": {
                    top: openedMenu ? 7 : 14,
                    left: openedMenu ? "50%" : 0,
                    width: openedMenu ? "0%" : "100%",
                },
            },
        });
    },
    contactUsButtonWrapper: __assign({ marginRight: rem(spacings.m), opacity: 0, transition: "opacity 0.65s ease", pointerEvents: "none" }, onBefore("sm", {
        display: "none",
    })),
    contactUsButtonWrapperShow: {
        opacity: 1,
        pointerEvents: "auto",
    },
});
export function Header(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var _r = useState(0), liftHeaderUpByPx = _r[0], setLiftHeaderUpByPx = _r[1];
    var _s = useState(false), showCtaButton = _s[0], setShowCtaButton = _s[1];
    var menuToggler = useRef(null);
    var localeSuggestionWrapperRef = useRef(null);
    var keyVisualButtonsPosition = ((_a = props.keyVisualRef) === null || _a === void 0 ? void 0 : _a.current) &&
        ((_c = (_b = props.keyVisualRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().top) + window.scrollY;
    var contactListPosition = ((_d = props.contactListRef) === null || _d === void 0 ? void 0 : _d.current) &&
        ((_f = (_e = props.contactListRef) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.getBoundingClientRect().top) + window.scrollY;
    var searchRef = useRef(null);
    useScrollPosition(function (_a) {
        var _b, _c, _d;
        var currPos = _a.currPos, prevPos = _a.prevPos;
        if (currPos.y === prevPos.y) {
            return;
        }
        var newVisibilityStatus = currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;
        var localeSuggestionWrapperHeight = ((_c = (_b = localeSuggestionWrapperRef === null || localeSuggestionWrapperRef === void 0 ? void 0 : localeSuggestionWrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.height) ||
            0;
        setLiftHeaderUpByPx(newVisibilityStatus || !((_d = props.topBar) === null || _d === void 0 ? void 0 : _d.value)
            ? 0
            : localeSuggestionWrapperHeight + HEADER_TOP_CONTENT_HEIGHT);
        if (!keyVisualButtonsPosition)
            return;
        var shouldShowButton = currPos.y - keyVisualButtonsPosition > -64 &&
            (contactListPosition ? contactListPosition - currPos.y > 64 : true);
        setShowCtaButton(shouldShowButton);
    }, [liftHeaderUpByPx, showCtaButton], undefined, true, 300);
    var _t = useState({
        openedMenu: false,
        openedSearch: false,
    }), headerElementToggle = _t[0], setHeaderElementToggle = _t[1];
    function menuToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: false,
            openedMenu: !s.openedMenu,
        }); });
    }
    function searchToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: !s.openedSearch,
            openedMenu: false,
        }); });
    }
    function handleClickOutside(event) {
        if (menuToggler.current && menuToggler.current.contains(event.target)) {
            setHeaderElementToggle(function (s) { return ({
                openedMenu: s.openedMenu,
                openedSearch: false,
            }); });
        }
        else if (searchRef.current && !searchRef.current.contains(event.target)) {
            headerElementToggle.openedSearch &&
                setHeaderElementToggle(function (s) { return ({
                    openedMenu: s.openedMenu,
                    openedSearch: false,
                }); });
        }
    }
    useEffect(function () {
        headerElementToggle.openedMenu
            ? (document.body.style.position = "fixed")
            : (document.body.style.position = "static");
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    });
    var openedMenu = headerElementToggle.openedMenu;
    var openedSearch = headerElementToggle.openedSearch;
    var styles = useStyles({ liftHeaderUpByPx: liftHeaderUpByPx, openedMenu: openedMenu, openedSearch: openedSearch });
    return (React.createElement("header", { className: cn(styles.header, styles.headerMixins), "data-testid": "header" },
        ((_g = props.topBar) === null || _g === void 0 ? void 0 : _g.value) && (React.createElement("div", { className: styles.headerTop },
            React.createElement(Container, null,
                React.createElement("div", { className: styles.headerTopContent },
                    React.createElement("nav", { className: styles.navLeft },
                        React.createElement("ul", null, (_h = props.metaNavigationLeft.value) === null || _h === void 0 ? void 0 : _h.map(function (l, idx) { return (React.createElement("li", { key: idx },
                            React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); }))),
                    React.createElement("nav", { className: styles.navRight },
                        React.createElement("ul", null, (_j = props.metaNavigationRight.value) === null || _j === void 0 ? void 0 :
                            _j.map(function (l, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); }),
                            props.userLogin.value && (React.createElement("li", null,
                                React.createElement("a", { href: "#" },
                                    React.createElement(LocalizedLabel, { section: "Global", label: "Login" }))))),
                        props.languagePicker.value && React.createElement(LanguagePicker, null)))))),
        React.createElement("div", { className: styles.headerBottom },
            React.createElement(Container, null,
                React.createElement("div", { className: styles.headerBottomContent },
                    React.createElement("a", { href: ((_k = props.logoLink) === null || _k === void 0 ? void 0 : _k.value) ? props.logoLink.value : undefined, className: styles.logo },
                        React.createElement("img", { src: ((_m = (_l = props.logo) === null || _l === void 0 ? void 0 : _l.value) === null || _m === void 0 ? void 0 : _m.url) || undefined, alt: "logo" })),
                    React.createElement("div", { ref: props.headerButtonPortalRef, className: cn(styles.contactUsButtonWrapper, showCtaButton && styles.contactUsButtonWrapperShow) }),
                    !props.hideSearch && ((_o = props.searchField) === null || _o === void 0 ? void 0 : _o.value) && (React.createElement(React.Fragment, null, headerElementToggle.openedSearch ? (React.createElement(FAIcon, { icon: "times", className: styles.search, onClick: searchToggle, "aria-controls": "search" })) : (React.createElement(FAIcon, { icon: "search", className: styles.search, onClick: searchToggle, "aria-controls": "search" })))),
                    ((_p = props.navigationMenu) === null || _p === void 0 ? void 0 : _p.value) && (React.createElement("button", { "aria-controls": "main-menu", className: styles.mainMenu, ref: menuToggler, onClick: menuToggle, onMouseUp: function () {
                            return menuToggler.current && menuToggler.current.blur();
                        } },
                        React.createElement("span", null, "Menu"),
                        React.createElement("div", { className: styles.hamburgerIcon, "aria-hidden": true },
                            React.createElement("div", null),
                            React.createElement("div", null),
                            React.createElement("div", null),
                            React.createElement("div", null))))))),
        React.createElement(MonsterNavigation, { isOpened: openedMenu, languagePicker: props.languagePicker, userLogin: props.userLogin }),
        React.createElement("div", { ref: searchRef },
            React.createElement(Search, { isOpened: headerElementToggle.openedSearch, searchPage: props.searchPage, homepageSearch: (_q = props.homepageSearch) === null || _q === void 0 ? void 0 : _q.expandedValue }))));
}
